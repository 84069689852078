<template>
  <div class="home">
<Navigation/>
    <div class="section bg-primary-3">
    <div class="container text-center">
      <h1>Infographics</h1>
      <div class="text-large">Graphs and more to educate your audience.</div>
    </div>
  </div>
<Posts Category="infographics" />


  </div>

</template>

<script>
import Navigation from "@/components/Navigation.vue"
import Posts from "@/components/PostCollection.vue"

export default {
  name: "home",
  components: {
    Navigation,
    Posts
  }
};
</script>
